import positiveNum from 'lib/validators/positiveNum'
import required from 'lib/validators/required'

export default function validationRules () {
  // console.log('validationRules');
  return {
    bidCap: [
      positiveNum,
    ],
    impressions: [
      positiveNum,
    ],
    conversions: [
      positiveNum,
    ],
    cpa: [
      positiveNum,
    ],
    cpc: [
      positiveNum,
    ],
    clicks: [
      positiveNum,
    ],
    margin: [
      positiveNum,
    ],
    cpm_percent: [
      positiveNum,
    ],
    agency_commission: [
      positiveNum,
    ],
    cpc_day_limit: [
      positiveNum,
    ],
    cpa_day_limit: [
      positiveNum,
    ],
    frequencyCount: [
      positiveNum,
    ],
    agencies: [
      required,
    ],
  }
}
