<template>
  <DragNDrop
    class="drag-n-drop-template"
    :accept="options.accept"
    :max-size-mb="options.maxSizeMb"
    :display-max-size-mb="options.displayMaxSizeMb"
    @change="$emit('change', $event)"
    @error="$emit('error', $event)"
  >
    <div class="drag-n-drop-template__icon">
      <SvgIcon
        :name="options.icon.name"
        :width="options.icon.width"
        :height="options.icon.height"
      />
    </div>
    <div class="text-l-bold drag-n-drop-template__title">
      {{ options.title }}
    </div>
  </DragNDrop>
</template>

<script>
import DragNDrop from 'components/DragNDrop'

export default {
  components: {
    DragNDrop,
  },

  props: {
    options: VueTypes.shape({
      title: VueTypes.string,
      icon: VueTypes.object,
      maxSizeMb: VueTypes.number.def(Infinity),
      displayMaxSizeMb: VueTypes.number.def(Infinity),
      accept: VueTypes.string,
    }),
  },
}
</script>

<style lang="scss" scoped>
.drag-n-drop-template {
  &__icon {
    fill: $--color-dark-gray;
  }

  &__title {
    color: $--color-dark-gray;
    margin-top: 14px;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
</style>
