<template>
  <ElRow
    class="group-targeting-age"
    :gutter="20"
  >
    <ElCol :span="12">
      <ElFormItem
        :label="locale.label"
        prop="ageFrom"
      >
        <ElSelect
          :placeholder="`${locale.fromPlaceholder} 13`"
          v-model="model.ageFrom"
          :disabled="disabled"
          @input="$emit('input')"
        >
          <ElOption
            v-for="item in agesFrom"
            :key="item"
            :value="item"
          />
        </ElSelect>
      </ElFormItem>
    </ElCol>

    <ElCol :span="12">
      <ElFormItem
        class="_transparent-label"
        :label="locale.label"
        prop="ageTo"
      >
        <ElSelect
          :placeholder="`${locale.toPlaceholder} 65`"
          v-model="model.ageTo"
          :disabled="disabled"
          @input="$emit('input')"
        >
          <ElOption
            v-for="item in agesTo"
            :key="item"
            :value="item"
          />
        </ElSelect>
      </ElFormItem>
    </ElCol>
  </ElRow>
</template>

<script>
import ElCol from 'element/ElCol'
import ElFormItem from 'element/ElFormItem'
import ElOption from 'element/ElOption'
import ElRow from 'element/ElRow'
import ElSelect from 'element/ElSelect'

export default {
  components: {
    ElRow,
    ElCol,
    ElFormItem,
    ElSelect,
    ElOption,
  },

  props: {
    model: VueTypes.object.isRequired,
    disabled: VueTypes.bool,
  },

  data: () => ({
    minAge: 13,
    maxAge: 65,
  }),

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('targeting.form.age.label'),
      fromPlaceholder: $locale('targeting.form.age.fromPlaceholder'),
      toPlaceholder: $locale('targeting.form.age.toPlaceholder'),
    }),
    agesFrom ({ model, minAge, maxAge }) {
      const result = []
      if (model.ageTo) {
        for (let i = minAge; i <= model.ageTo; i++) {
          result.push(i)
        }
      }
      else {
        for (let i = minAge; i <= maxAge; i++) {
          result.push(i)
        }
      }
      return result
    },
    agesTo ({ model, minAge, maxAge }) {
      const result = []
      if (model.ageFrom) {
        for (let i = model.ageFrom; i <= maxAge; i++) {
          result.push(i)
        }
      }
      else {
        for (let i = minAge; i <= maxAge; i++) {
          result.push(i)
        }
      }
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
._transparent-label {
  ::v-deep {
    .el-form-item__label {
      color: transparent;
    }
  }
}

.group-targeting-age {
  margin-bottom: 20px;
}
</style>
