<template>
  <ElFormItem
    :label="locale.label"
    prop="gender"
  >
    <ElSelect
      :placeholder="locale.placeholder"
      v-model="model.gender"
      :disabled="disabled"
      @input="$emit('input')"
    >
      <ElOption
        v-for="item in genders"
        :key="item.id"
        :value="item.id"
        :label="item.title"
      />
    </ElSelect>
  </ElFormItem>
</template>

<script>
import ElFormItem from 'element/ElFormItem'
import ElOption from 'element/ElOption'
import ElSelect from 'element/ElSelect'

export default {
  components: {
    ElFormItem,
    ElSelect,
    ElOption,
  },

  props: {
    model: VueTypes.object.isRequired,
    disabled: VueTypes.bool,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('targeting.form.gender.label'),
      placeholder: $locale('targeting.form.gender.placeholder'),
    }),
    genders ({ $store }) {
      return $store.getters['dictionaries/campaignDictionaries'].gender
    },
  },
}
</script>
