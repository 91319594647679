import { prerollAdSetModelToPayloadAdapter } from 'lib/adapters/partner/ad-set/preroll'
import { Api } from 'lib/api'
import { IPrerollAdSetModel } from 'lib/types/partner-ad-set/preroll'
import {
  IPrerollAdSetCreationPayloadFrom,
  IPrerollAdSetListPayloadFrom,
  IPrerollAdSetPayloadFrom,
} from 'lib/types/payloads/partner-ad-set/preroll'
import { IMessage, IResponse, IResponseData, IResponseMessage, IStatus } from 'lib/types/response'

export const createPrerollAdset = async (slug: string, data: IPrerollAdSetModel) => {
  const formattedData = {
    slug,
    ...prerollAdSetModelToPayloadAdapter(data),
  }
  return Api
    .post<IResponse<IPrerollAdSetCreationPayloadFrom & IMessage>>('partner/campaigns/preroll/ad-set/create', formattedData)
    .then(res => res)
}

export const updatePrerollAdset = async (slug: string, data: IPrerollAdSetModel) => {
  const formattedData = {
    slug,
    ...prerollAdSetModelToPayloadAdapter(data),
  }
  return Api
    .post<IResponse<IPrerollAdSetPayloadFrom & IMessage>>('partner/campaigns/preroll/ad-set/update', formattedData)
    .then(res => res)
}

export const getPrerollAdsetInfo = async (slug: string) => {
  return Api
    .get<IResponseData<IPrerollAdSetPayloadFrom>>('partner/campaigns/preroll/ad-set/info', { slug })
    .then(res => res)
}

export const getPrerollAdsetList = async (data: { page: number }) => {
  return Api
    .get<IResponse<IPrerollAdSetListPayloadFrom>>('partner/campaigns/preroll/ad-set', data)
    .then(res => res)
}

export const verifyAttachment = async (data: { format: string; unit: string }) => {
  return Api
    .post<IStatus>('partner/campaigns/preroll/ad/attach/verify', data)
    .then(res => res)
}

export const deleteAttachment = async (data: { slug: string; field: string }) => {
  return Api
    .post<IResponseMessage>('partner/campaigns/preroll/ad-set/attach/delete', data)
    .then(res => res)
}
