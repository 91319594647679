<template>
  <ElFormItem prop="mature">
    <ElCheckbox
      v-model="model.mature"
      :disabled="disabled"
      @input="$emit('input')"
    >
      {{ locale.label }}
    </ElCheckbox>
  </ElFormItem>
</template>

<script>
import ElCheckbox from 'element/ElCheckbox'
import ElFormItem from 'element/ElFormItem'

export default {
  components: {
    ElFormItem,
    ElCheckbox,
  },

  props: {
    model: VueTypes.object.isRequired,
    disabled: VueTypes.bool,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('targeting.form.ageRestrictions.label'),
    }),
  },
}
</script>
