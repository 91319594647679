<template>
  <DragNDropTemplate
    :options="options"
    @change="$emit('change', $event)"
    @error="$emit('error', $event)"
  />
</template>

<script>
import DragNDropTemplate from './DragNDropTemplate'

export default {
  components: {
    DragNDropTemplate,
  },

  props: {
    maxSizeMb: VueTypes.number,
    displayMaxSizeMb: VueTypes.number,
  },

  computed: {
    title: ({ $rootLocale }) => $rootLocale('views.partner.creative.form.files.instructions.dragImageOrVideo'),
    options: ({ title, maxSizeMb, displayMaxSizeMb }) => ({
      title,
      icon: {
        name: 'creative-zip',
        width: '40px',
        height: '40px',
      },
      maxSizeMb: maxSizeMb || 5,
      displayMaxSizeMb: displayMaxSizeMb || 5,
      accept: 'unit',
    }),
  },
}
</script>
