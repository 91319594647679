<template>
  <DashboardSection
    class="group-actions"
    no-left
  >
    <div class="group-actions__inner">
      <div class="group-actions__inner-left">
        <ElButton
          type="primary"
          plain
          @click="onBack"
        >
          {{ $t('button.back.static') }}
        </ElButton>
      </div>
      <div class="group-actions__inner-right">
        <ElButton
          type="primary"
          :loading="pending"
          @click="onSubmit"
        >
          {{ $t('button.save') }}
        </ElButton>
      </div>
    </div>
  </DashboardSection>
</template>

<script>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import ElButton from 'element/ElButton'

export default {
  components: {
    DashboardSection,
    ElButton,
  },

  computed: {
    pending: ({ $store }) => $store.getters['group/pending'],
    isEdit: ({ $route }) => $route.name === 'edit-group',
  },

  methods: {
    onBack () {
      this.$router.go(-1)
    },
    onSubmit () {
      this.$parent.$refs.form.validate((isSuccess) => {
        if (isSuccess) {
          this.isEdit ? this.update() : this.create()
        }
      })
    },
    async create () {
      const res = await this.$store.dispatch('group/create')
      await this.$router.push({
        name: 'create-creative',
        params: {
          campaignSlug: res.campaign.slug,
          groupSlug: res.ad_set.slug,
        },
      })
    },
    async update () {
      await this.$store.dispatch('group/update')
      await this.$store.dispatch('partner/fetchCampaignStructure', this.$route.params.campaignSlug)
    },
  },
}
</script>

<style lang="scss" scoped>
.group-actions {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  ::v-deep {
    .el-button {
      width: 150px;
    }
  }
}
</style>
