import { performanceAdSetModelToPayloadAdapter } from 'lib/adapters/partner/ad-set/performance'
import { Api } from 'lib/api'
import { IPerformanceAdSetModel } from 'lib/types/partner-ad-set/performance'
import {
  IPerformanceAdSetCreationPayloadFrom,
  IPerformanceAdSetListPayloadFrom,
  IPerformanceAdSetPayloadFrom,
} from 'lib/types/payloads/partner-ad-set/performance'
import { IMessage, IResponse, IResponseData, IResponseMessage, IStatus } from 'lib/types/response'

export const createPerformanceAdset = async (slug: string, data: IPerformanceAdSetModel) => {
  const formattedData = {
    slug,
    ...performanceAdSetModelToPayloadAdapter(data),
  }
  return Api
    .post<IResponse<IPerformanceAdSetCreationPayloadFrom & IMessage>>('partner/campaigns/performance/ad-set/create', formattedData)
    .then(res => res)
}

export const updatePerformanceAdset = async (slug: string, data: IPerformanceAdSetModel) => {
  const formattedData = {
    slug,
    ...performanceAdSetModelToPayloadAdapter(data),
  }
  return Api
    .post<IResponse<IPerformanceAdSetPayloadFrom & IMessage>>('partner/campaigns/performance/ad-set/update', formattedData)
    .then(res => res)
}

export const getPerformanceAdsetInfo = async (slug: string) => {
  return Api
    .get<IResponseData<IPerformanceAdSetPayloadFrom>>('partner/campaigns/performance/ad-set/info', { slug })
    .then(res => res)
}

export const getPerformanceAdsetList = async (data: { page: number }) => {
  return Api
    .get<IResponse<IPerformanceAdSetListPayloadFrom>>('partner/campaigns/performance/ad-set', data)
    .then(res => res)
}

export const verifyAttachment = async (data: { format: string; unit: string }) => {
  return Api
    .post<IStatus>('partner/campaigns/performance/ad/attach/verify', data)
    .then(res => res)
}

export const deleteAttachment = async (data: { slug: string; field: string }) => {
  return Api
    .post<IResponseMessage>('partner/campaigns/performance/ad-set/attach/delete', data)
    .then(res => res)
}
