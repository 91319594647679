<template>
  <DashboardSection
    :title="locale.title"
    class="group-targeting"
  >
    <GroupTargetingAgencies
      :model="model"
      :disabled="disableFields"
    />
    <!-- <GroupTargetingCPM
      :model="model"
      @input="onInput"
    /> -->
    <div class="group-targeting__separator" />
    <GroupTargetingStreamers
      :model="model"
      @input="onInput"
    />
    <div class="group-targeting__separator" />
    <component
      v-for="(item, i) in fields"
      :key="i"
      :is="item"
      :model="model"
      :disabled="disableFields"
      @input="onInput"
    />
    <GroupTargetingEvaluation />
  </DashboardSection>
</template>

<script>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import GroupTargetingAgencies from 'Partner/pages/PartnerGroup/components/GroupTargeting/GroupTargetingAgencies.vue'
import GroupTargetingCountries from 'Partner/pages/PartnerGroup/components/GroupTargeting/GroupTargetingCountries.vue'
import GroupTargetingCPM from 'Partner/pages/PartnerGroup/components/GroupTargeting/GroupTargetingCPM.vue'
import GroupTargetingLanguages from 'Partner/pages/PartnerGroup/components/GroupTargeting/GroupTargetingLanguages.vue'
import GroupTargetingStreamers from 'Partner/pages/PartnerGroup/components/GroupTargeting/GroupTargetingStreamers.vue'
import GroupTargetingTags from 'Partner/pages/PartnerGroup/components/GroupTargeting/GroupTargetingTags.vue'

import GroupTargetingAge from './components/GroupTargetingAge'
import GroupTargetingAgeRestrictions from './components/GroupTargetingAgeRestrictions'
import GroupTargetingEvaluation from './components/GroupTargetingEvaluation'
// unrefactored components
import GroupTargetingGender from './components/GroupTargetingGender'

export default {
  components: {
    DashboardSection,
    GroupTargetingAgencies,
    GroupTargetingStreamers,
    GroupTargetingLanguages,
    GroupTargetingCountries,
    GroupTargetingGender,
    GroupTargetingAge,
    GroupTargetingAgeRestrictions,
    GroupTargetingTags,
    GroupTargetingEvaluation,
    GroupTargetingAgencies,
    GroupTargetingCPM,
  },

  props: {
    model: VueTypes.object.isRequired,
  },

  data: () => ({
    fields: [
      GroupTargetingLanguages,
      GroupTargetingCountries,
      GroupTargetingGender,
      GroupTargetingAge,
      GroupTargetingAgeRestrictions,
      GroupTargetingTags,
    ],
  }),

  computed: {
    locale: ({ $locale }) => ({
      title: $locale('targeting.title'),
    }),
    disableFields ({ model }) {
      return !!model.streamers.length && !model.excludeStreamers
    },
  },

  created () {
    this.fetchAudience()
  },

  methods: {
    onInput () {
      this.fetchAudience()
    },
    fetchAudience () {
      this.$store.dispatch('group/fetchAudience')
    },
  },
}
</script>

<style lang="scss" scoped>
.group-targeting {
  position: relative;

  &__separator {
    border-top: $--border-base;
    margin-bottom: 20px;
  }

  ::v-deep {
    .advice {
      position: absolute;
      left: calc(100% + 24px);
      top: 32px;

      @media (max-width: $--tablet-portrait) {
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
