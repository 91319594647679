<template>
  <ElFormItem prop="frequency">
    <ElSelect
      :placeholder="locale.title"
      :value="value"
      @input="$emit('input', $event)"
    >
      <ElOption
        v-for="item in frequency"
        :key="item.id"
        :value="item.id"
        :label="item.title"
      />
    </ElSelect>
  </ElFormItem>
</template>

<script>
import ElFormItem from 'element/ElFormItem'
import ElOption from 'element/ElOption'
import ElSelect from 'element/ElSelect'

export default {
  components: {
    ElFormItem,
    ElSelect,
    ElOption,
  },

  props: {
    value: VueTypes.oneOfType([VueTypes.string, VueTypes.nullType]),
  },

  computed: {
    locale: ({ $locale }) => $locale('settings.form.frequency'),
    frequency: ({ $store }) => $store.getters['dictionaries/campaignDictionaries'].frequency,
  },
}
</script>
