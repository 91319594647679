import { computed, reactive, Ref, ref, watch } from 'vue'
import { cloneDeep } from 'lodash'
import { defineStore } from 'pinia'

import { vm } from '@/main'
import { prerollAdSetToModelAdapter } from 'lib/adapters/partner/ad-set/preroll'
import { createPrerollAdset, deleteAttachment, getPrerollAdsetInfo, updatePrerollAdset, verifyAttachment } from 'lib/api/modules/partner/ad-set/preroll'
import { getPrerollCampaignDictionaries } from 'lib/api/modules/partner/campaign/preroll'
import { AdFormat } from 'lib/types/ad-format'
import { PartnerAdSetPayType } from 'lib/types/partner-ad-set'
import { IPrerollAdSetModel } from 'lib/types/partner-ad-set/preroll'
import { IPrerollCampaignDictionaries } from 'lib/types/partner-campaign/preroll'
import { IPrerollAdSetPayloadFrom } from 'lib/types/payloads/partner-ad-set/preroll'

import ElMessage from 'element/ElMessage'

const defaultModel: IPrerollAdSetModel = {
  title: '',
  title_alternative: '',
  description: '',
  platform: 'youtube',
  start: '',
  end: '',
  format: AdFormat.PREROLL,
  payType: PartnerAdSetPayType.IMPRESSIONS,
  bidCap: 0,
  impressions: 0,
  bidCpa: 0,
  budget: 0,
  streamers: [],
  excludeStreamers: false,
  languages: [],
  excludeLanguages: false,
  countries: [],
  excludeCountries: false,
  tags: [],
  excludeTags: false,
  productUrl: '',
  videoDescriptionText: '',
  unit: null,
  enabled: false,
  targetCtr: null,
  targetCpa: null,
  pixelClicks: [],
  pixelClicksScripts: '',
  legalCompliance: {
    erid: {
      media: null,
      text: null,
    },
    marker: {
      media: null,
      text: null,
    },
  },
}

export const usePrerollAdSetStore = defineStore('usePrerollAdSet', () => {
  const model: IPrerollAdSetModel = reactive(cloneDeep(defaultModel))
  const adSet: Ref<IPrerollAdSetPayloadFrom | null> = ref(null)
  const dictionaries: Ref<IPrerollCampaignDictionaries | null> = ref(null)
  const dictionariesLoading = ref(false)
  const groupLoading = ref(false)
  const fileIsUploading: Ref<boolean> = ref(false)
  const fileErrors: Ref<string[]> = ref([])

  const locale = computed(() => vm.$root.$i18n.locale)
  const loading = computed(() => dictionariesLoading.value || groupLoading.value)
  const currency = computed(() => adSet.value?.campaign?.advertiser?.wallet.currency.code)
  const mode = computed(() => adSet.value ? 'edit' : 'create')

  const setModel = (newModel: IPrerollAdSetModel) => {
    Object.assign(model, newModel)
    setFileErrors([])
  }

  const fetchDictionaries = async () => {
    dictionariesLoading.value = true
    try {
      const res = await getPrerollCampaignDictionaries()
      dictionaries.value = res
    }
    finally {
      dictionariesLoading.value = false
    }
  }

  const fetchGroup = async (slug: string) => {
    groupLoading.value = true
    const res = await getPrerollAdsetInfo(slug)
    adSet.value = res.data
    groupLoading.value = false
  }

  const createGroup = async (campaignSlug: string) => {
    groupLoading.value = true

    try {
      const res = await createPrerollAdset(campaignSlug, model)
      return res
    }
    finally {
      groupLoading.value = false
    }
  }

  const updateGroup = async () => {
    model.pixelClicks = model.pixelClicks.filter(item => item.value !== '')
    groupLoading.value = true
    const slug = adSet.value?.slug || ''


    try {
      await updatePrerollAdset(slug, model)
      ElMessage.success(vm.$t('views.partner.group.messages.groupUpdatedSuccesfully'))
    }
    finally {
      groupLoading.value = false
    }
  }

  const resetGroup = () => {
    groupLoading.value = true
    setModel(defaultModel)
    adSet.value = null
    setFileErrors([])

    // for usability
    setTimeout(() => {
      groupLoading.value = false
    }, 200)
  }

  const deletePreview = async () => {
    if (mode.value === 'create' || fileErrors.value.length) {
      setFileErrors([])
      model.unit = null
      return
    }
    groupLoading.value = true
    try {
      const res = await deleteAttachment({
        field: 'unit',
        slug: adSet.value?.slug || '',
      })
      if (res.status) {
        model.unit = null
      }
    }
    finally {
      groupLoading.value = false
    }
  }

  const setFileErrors = (errors: string[]) => {
    fileErrors.value = errors
  }

  watch(adSet, (newVal) => {
    if (newVal) {
      const newModel = prerollAdSetToModelAdapter(newVal)
      setModel(newModel)
    }
  })

  watch(locale, () => {
    fetchDictionaries()
  })

  return {
    model,
    adSet,
    mode,
    dictionaries,
    currency,
    loading,
    fileIsUploading,
    fileErrors,
    setModel,
    setFileErrors,
    fetchDictionaries,
    fetchGroup,
    createGroup,
    updateGroup,
    resetGroup,
    deletePreview,
  }
})
