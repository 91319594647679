<template>
  <Advice
    v-loading="loading"
    class="evaluation"
    :label="locale.label"
  >
    <div class="evaluation__row">
      <p class="text-m-regular">
        {{ locale.description }}
      </p>
    </div>

    <div class="evaluation__row">
      <p class="text-m-regular">
        {{ locale.size }}
      </p>
      <p class="text-l-bold">
        {{ audience.impressions | toRanks }}
      </p>
    </div>

    <div class="evaluation__row">
      <p class="text-m-regular">
        {{ locale.reach }}
      </p>
      <p class="text-l-bold">
        {{ audience.reach | toRanks }}
      </p>
    </div>

    <div class="evaluation__row">
      <p class="text-m-regular">
        {{ locale.streamersNumber }}
      </p>
      <p class="text-l-bold">
        {{ audience.streamers || '–' }}
      </p>
    </div>

    <div class="evaluation__link">
      <p class="text-m-regular">
        <a href="#">{{ locale.howCalculated }}</a>
      </p>
    </div>
  </Advice>
</template>

<script>
import Advice from 'components/Advice'
import loading from 'element/Loading'

export default {
  components: {
    Advice,
  },

  directives: {
    loading,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('targeting.evaluation.label'),
      description: $locale('targeting.evaluation.description'),
      size: $locale('targeting.evaluation.size'),
      reach: $locale('targeting.evaluation.reach'),
      streamersNumber: $locale('targeting.evaluation.streamersNumber'),
      howCalculated: $locale('targeting.evaluation.howCalculated'),
    }),
    audience: ({ $store }) => $store.getters['group/audience'],
    loading: ({ $store }) => $store.getters['group/loadingAudience'],
  },
}
</script>

<style lang="scss" scoped>
.evaluation {
  &__row {
    margin-bottom: 16px;
  }
  &__link {
    margin-top: 24px;
  }
}
</style>
