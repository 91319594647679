import { CallbackFunction, Trigger } from 'lib/validators/consts/types'

import { i18n } from '@/i18n'

export default {
  validator: (rule: any, value: any, callback: CallbackFunction) => {
    const isValid = typeof +value === 'number' && +value >= 0
    if (!isValid) {
      callback(new Error(i18n.tc('validator.positiveNum')))
    }
    else {
      callback()
    }
  },
  trigger: [Trigger.Blur, Trigger.Change],
}
