<template>
  <DashboardSection :title="locale.title">
    <p>{{ locale.description }}</p>
    <br>

    <GroupSettingsTitle :model="model" />
    <GroupSettingsDescription :model="model" />
    <GroupSettingsPlatform :model="model" />
    <GroupSettingsFormat :model="model" />
    <GroupSettingsSchedule :model="model" />
    <GroupSettingsTimezone v-model="model.timezone" />
    <GroupSettingsImpressionsBudget
      :model="model"
      :strategy-payment-types="strategyPaymentTypes"
      :group-type="'brand_awareness'"
    />
    <GroupSettingsTargets
      :model="model"
      :group-type="'brand_awareness'"
    />
    <GroupSettingsFrequency :model="model" />
  </DashboardSection>
</template>

<script>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import GroupSettingsDescription from 'Partner/pages/PartnerGroup/components/GroupSettings/GroupSettingsDescription.vue'
import GroupSettingsPlatform from 'Partner/pages/PartnerGroup/components/GroupSettings/GroupSettingsPlatform.vue'
import GroupSettingsSchedule from 'Partner/pages/PartnerGroup/components/GroupSettings/GroupSettingsSchedule.vue'
import GroupSettingsTargets from 'Partner/pages/PartnerGroup/components/GroupSettings/GroupSettingsTargets.vue'
import GroupSettingsTimezone from 'Partner/pages/PartnerGroup/components/GroupSettings/GroupSettingsTimezone'
import GroupSettingsTitle from 'Partner/pages/PartnerGroup/components/GroupSettings/GroupSettingsTitle.vue'

import GroupSettingsFormat from './components/GroupSettingsFormat'
import GroupSettingsFrequency from './components/GroupSettingsFrequency'
import GroupSettingsImpressionsBudget from './components/GroupSettingsImpressionsBudget.vue'

export default {
  components: {
    DashboardSection,
    GroupSettingsTitle,
    GroupSettingsDescription,
    GroupSettingsPlatform,
    GroupSettingsFormat,
    GroupSettingsSchedule,
    GroupSettingsImpressionsBudget,
    GroupSettingsFrequency,
    GroupSettingsTimezone,
    GroupSettingsTargets,
  },

  props: {
    model: VueTypes.object.isRequired,
    strategyPaymentTypes: VueTypes.array.isRequired,
  },

  computed: {
    locale: ({ $locale }) => ({
      title: $locale('settings.title'),
      description: $locale('settings.description'),
    }),
  },
}
</script>
