import { AdFormatType } from 'lib/types/ad-format'

import { IdType } from '../base-types'

export interface IPartnerAdSetFormat {
  id: AdFormatType
  title: string
  description: string
  icon: string
}

export interface IPartnerAdSetHolding {
  id: IdType
  title: string
  description: string
  logo: string
  advetisers: number
}

export interface IPartnerAdSetStreamer {
  id: IdType
  name: string
}

export enum PartnerAdSetPayType {
  IMPRESSIONS = 'impressions',
  ACTIONS = 'actions'
}
