import { CallbackFunction, Trigger } from 'lib/validators/consts/types'

import { i18n } from '@/i18n'

const checkScripts = (value: string) => {
}
export default {
  validator: (rule: any, value: any, callback: CallbackFunction) => {
    const isValid = checkScripts(value)
  },
  trigger: [Trigger.Submti],
  // trigger: [Trigger.Change, Trigger.Blur],
}
