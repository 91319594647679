import { CallbackFunction, Trigger } from 'lib/validators/consts/types'

import { i18n } from '@/i18n'

const checkUrls = (value: string) => {
  const isValidUrl = (url: string) => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?' + // Протокол
          '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.?)+[a-zA-Z]{2,}|' + // Доменное имя
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // или IP (v4) адрес
          '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // Порт и путь
          '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // Параметры запроса
          '(\\#[-a-zA-Z\\d_]*)?$', 'i') // Якорь

    // Если URL не соответствует шаблону, возвращаем false
    if (!urlPattern.test(url)) {
      return false
    }

    // Проверка на использование https
    return url.startsWith('https://')
  }

  // Функция для проверки, является ли строка URL
  const isUrl = (value: string) => {
    try {
      const url = new URL(value)
      return true
    }
    catch (err) {
      return false
    }
  }

  try {
    // Проверка корректности URL
    const validUrl = isValidUrl(value)
    const isUrlResult = isUrl(value)

    // Если обе проверки прошли успешно, возвращаем true
    return validUrl && isUrlResult
  }
  catch (err) {
    return false
  }
}
export default {
  validator: (rule: any, value: any, callback: CallbackFunction) => {
    const isValid = checkUrls(value)
    if (!isValid) {
      callback(new Error(i18n.tc('validator.urlIsInvalid')))
    }
    else {
      callback()
    }
  },
  trigger: [Trigger.Submti],
  // trigger: [Trigger.Change, Trigger.Blur],
}
