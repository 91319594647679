import { render, staticRenderFns } from "./GroupSettingsImpressionsBudget.vue?vue&type=template&id=e89c0146&scoped=true&"
import script from "./GroupSettingsImpressionsBudget.vue?vue&type=script&setup=true&lang=ts&"
export * from "./GroupSettingsImpressionsBudget.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./GroupSettingsImpressionsBudget.vue?vue&type=style&index=0&id=e89c0146&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e89c0146",
  null
  
)

export default component.exports