<template>
  <PartnerCampaignLayout>
    <div>
      <component
        :is="component"
        ref="groupComponent"
      />
    </div>
  </PartnerCampaignLayout>
</template>

<script>
import { getCampaignTypeBySlug } from 'lib/helpers/getCampaignTypeBySlug'
import { Campaign } from 'lib/types/campaign-type'

import PartnerCampaignLayout from 'components/layouts/PartnerCampaignLayout/index.vue'

import BrandAwarenessGroup from './BrandAwarenessGroup/index.vue'
import PerformanceGroup from './PerformanceGroup/index.vue'
import PrerollGroup from './PrerollGroup/index.vue'

// Vue-router v3 не поддерживает leave/update хуки в composition api
export default {
  components: {
    PartnerCampaignLayout,
    BrandAwarenessGroup,
    PerformanceGroup,
    PrerollGroup,
  },
  computed: {
    type: ({ $route }) => getCampaignTypeBySlug($route.params.campaignSlug),
    component: ({ type }) => {
      switch (type) {
        case Campaign.PERFORMANCE: return PerformanceGroup
        case Campaign.PREROLL: return PrerollGroup
        case Campaign.BRAND_AWARENESS: return BrandAwarenessGroup
        default: return null
      }
    },
  },
  beforeRouteLeave (to, from, next) {
    this.$refs.groupComponent.getGroup(to)
    next()
  },
  beforeRouteUpdate (to, from, next) {
    this.$refs.groupComponent.getGroup(to)
    next()
  },
}
</script>
